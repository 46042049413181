import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Environment {
  version: string;
  hash: string;
  production: boolean;
  environment: 'local' | 'dev' | 'qa' | 'preprod' | 'prod';
  apiMockData: boolean;
  authClientId: string;
  baseUrl: string;
  apiBaseUrl: string;
  loginPath: string;
  logoutPath: string;
  appInsights: { instrumentationKey: string };
  locales: string[];
  footerLinks: {
    automotiveTransportation: string;
    miningSolutions: string;
    nutrition: string;
    oilfieldChemicals: string;
    plasticAdditives: string;
  };
}
