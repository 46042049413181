import { Injectable } from '@angular/core';
import { CoreCookieService } from '@ev-portals/ev/frontend/util';

@Injectable({
  providedIn: 'root',
})
export class CookieService extends CoreCookieService {
  getImpersonationToken(): string | null {
    return this.getCookie('impersonated_user');
  }
}
