import { ArticleUomDto } from '@ev-portals/cp/frontend/shared/api-client';

export class QuantityErrorUtil {
  static parseQuantityError(
    errorMessage: string,
  ): Omit<ArticleUomDto, 'abbreviation' | 'longText'> {
    const quantityErrorMessage = errorMessage.split(':')[1]?.trim() as string;
    const [lowerEnd, upperEnd, uom] = quantityErrorMessage?.split('/') as [string, string, string];

    const decimals = upperEnd?.split('.')[1]?.length as number;
    const packageSize =
      Math.round((parseFloat(upperEnd) - parseFloat(lowerEnd)) * 1000000) / 1000000;

    return { uom, packageSize, decimals };
  }
}
