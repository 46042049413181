import { Injectable } from '@angular/core';
import {
  CoreAnalyticsService,
  PianoEventData,
  PianoEventType,
  PianoUniversalData,
} from '@ev-portals/ev/frontend/util';

import { PageName } from './model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends CoreAnalyticsService {
  async initLocations(
    business_partner_ship_to: string | undefined,
    business_partner_sold_to: string | undefined,
  ): Promise<void> {
    this.defaultData.business_partner_ship_to = business_partner_ship_to;
    this.defaultData.business_partner_sold_to = business_partner_sold_to;

    const utag = await this.utag;
    utag.data.business_partner_ship_to = business_partner_ship_to;
    utag.data.business_partner_sold_to = business_partner_sold_to;
  }

  /**
   * Used by the authenticationService on user changes to set the customer type
   */
  async initCustomer(customer_type: string | undefined): Promise<void> {
    this.defaultData.customer_type = customer_type;

    const utag = await this.utag;
    utag.data.customer_type = customer_type;
  }

  override viewPage(page_name: PageName, page_category: string | undefined): Promise<void> {
    return this.trackView({ page_name, page_category });
  }

  viewProductDetails(product_name: string, product_id: string): Promise<void> {
    return this.trackView({
      page_name: 'product-details',
      page_category: 'products',
      product_name: product_name,
      product_id: product_id,
    });
  }

  viewPrivacyPolicy(): Promise<void> {
    return this.trackView({
      page_name: 'privacy-policy',
      page_category: 'privacy-policy',
      page_privacy_policy: 'true',
    });
  }

  trackSearch(search_term: string): Promise<void> {
    const utagData: PianoUniversalData = {
      page_name: 'product-list',
      page_category: 'products',
      search_term,
    };

    return this.trackView(utagData);
  }

  override async trackEvent(
    event_category: EventCategory,
    event_action: EventAction,
    event_type: PianoEventType = 'action',
    event_value?: string,
    event_goal?: 'true' | 'false',
  ): Promise<void> {
    const eventData: PianoEventData = {
      event_category,
      event_action,
      event_type,
      event_value,
      event_goal,
    };

    const completeUtagData = { ...this.defaultData, ...eventData };

    const utag = await this.utag;
    utag.link(completeUtagData);
  }
}

export type EventAction =
  | 'productSearchFound'
  | 'productSearchNotFound'
  | 'placeOrderDialogWorldAccountClick'
  | 'placeOrderDialogBtcClick'
  | 'placeOrderDialogFirstTimeOrderClick'
  | 'placeOrderDialogExit'
  | 'documentDownloadClick'
  | 'documentRequestClick'
  | 'requestExit'
  | 'contactExit'
  | 'login'
  | 'logout'
  | 'requestPriceClick'
  | 'addArticleToCart'
  | 'removeArticleFromCart'
  | 'checkout'
  | 'requestSubmit'
  | 'contactUsSubmit'
  | 'downloadOrderDocument'
  | 'deleteCurrentCart';

export type EventCategory =
  | 'product-list'
  | 'product-details'
  | 'requests'
  | 'order'
  | 'purchase'
  | 'info'
  | 'auth';
